import React, { useState } from "react";
import { VaultData } from "../../../assets/data/vaults";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  getActiveVault,
  storeActiveVault,
  storeSelectedSubVault,
} from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { TbPointFilled } from "react-icons/tb";
import { Space } from "antd";
import { myLayoutActions } from "../../../store/layout";
import "../../../styles/sideBar.css";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {},
}));

interface vaultProps {
  activeTabs?: any;
  setActiveTabs?: any;
  isHover?: any;
  setIsHover?: any;
}

const SideBar = ({
  activeTabs,
  setActiveTabs,
  isHover,
  setIsHover,
}: vaultProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [selectedTabIndex, setSelectedTabIndex] = useState(getActiveVault());
  const dispatch = useDispatch();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const styles = {
    border: {
      border: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    shadow: {
      boxShadow: "0px 2px 4px 0px rgba(3, 2, 41, 0.10)",
    },
  };
  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsVaultSideNavOpen(!isHover));
  };

  return (
    <>
      <div
        className="sBar overflow-hidden"
        style={styles.shadow}
        onMouseEnter={() => (setIsHover(true), handleSideNav())}
        onMouseLeave={() => (setIsHover(false), handleSideNav())}
      >
        <ul className=" text-base text-[#030229] font-normal">
          {VaultData.map((vault: any, index: number) => {
            return (
              <li
                className="flex py-1 gap-1"
                key={vault.id}
                onClick={() => {
                  storeActiveVault(vault.id);
                  setSelectedTabIndex(vault.id);
                  localStorage.setItem("activeSideVault", vault.id);
                }}
              >
                {vault.dropDown ? (
                  <Accordion
                    expanded={expanded === vault.id}
                    onChange={handleChange(vault.id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      className="w-[250px]"
                    >
                      <Typography
                        className={`flex gap-1 text-[#030229] text-base`}
                      >
                        <p
                          className={`text-lg pt-1  ${
                            selectedTabIndex === vault.id
                              ? "fill-[#605BFF] vaultIcon"
                              : "#0F0F47"
                          }`}
                        >
                          {vault.icon}
                        </p>{" "}
                        <span
                          className={`pt-1 ${
                            selectedTabIndex === vault?.id
                              ? "text-[#605BFF]"
                              : "text-[#030229]"
                          }`}
                        >
                          {vault.title}
                        </span>{" "}
                      </Typography>
                    </AccordionSummary>
                    <MuiAccordionDetails>
                      <Typography className="pxl-1">
                        <ul>
                          {vault.dropDown.map((item: any) => {
                            return (
                              <li
                                key={item?.menu}
                                className={`text-[#03022990] cursor-pointer  list-none font-medium text-base py-3 relative flex items-center  ${
                                  localStorage.getItem("selectedSubVault") ===
                                  item.menu
                                    ? isHover && "bg-[#F5F6FA] rounded-md pl-1"
                                    : ""
                                }`}
                                onClick={() => {
                                  const selectedSubVault = item.menu;
                                  storeSelectedSubVault(selectedSubVault);
                                  setActiveTabs(item.activeTab);
                                  localStorage.setItem(
                                    "activeNav",
                                    item.activeTab
                                  );
                                }}
                              >
                                <div
                                  className={` ${isHover ? "block" : "hidden"}`}
                                >
                                  <Space>
                                    <TbPointFilled className="text-[#03022940]" />{" "}
                                    {item.menu}
                                  </Space>{" "}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </Typography>
                    </MuiAccordionDetails>
                  </Accordion>
                ) : (
                  <div
                    className="flex ml-4 cursor-pointer"
                    onClick={() => setActiveTabs(vault.activeTab)}
                  >
                    <p
                      className={`text-lg pt-2 pr-2  ${
                        selectedTabIndex === vault.id
                          ? "fill-[#605BFF] vaultIcon"
                          : "#0F0F47"
                      }`}
                    >
                      {vault.icon}
                    </p>{" "}
                    <span
                      className={`pt-2 ${
                        selectedTabIndex === vault.id
                          ? "text-[#605BFF]"
                          : "text-[#030229]"
                      }`}
                    >
                      {vault.title}
                    </span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default SideBar;
