import { CSVLink } from "react-csv";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDataToExport,
  openCSVButton,
} from "../../../store/layout/actions";
import { myCSVAction } from "../../../store/csvDownload";
import { Button, Tooltip } from "antd";
import { format } from "date-fns";
import { LiaFileExportSolid } from "react-icons/lia";
interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
  directory?: any;
}
const CSVExportButton: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
  directory,
}) => {
  const { CSV } = useSelector((state: any) => state);
  const csvLinkRef = React.useRef<any>(null);
  const dispatch = useDispatch();

  const today = new Date();
  const currentDay = format(today, "yyyy-MM-dd");

  const handleButtonClick = async (fileName: string) => {
    await handleDataToExport(directory)(dispatch);
    await openCSVButton(true)(dispatch);
    dispatch(myCSVAction.setFileToExport(fileName));
  };

  useEffect(() => {
    if (CSV?.allowcsvDownload && csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
    openCSVButton(false)(dispatch);
    handleDataToExport(null)(dispatch);
    dispatch(myCSVAction.setCSVDownloadedData(null));
    dispatch(myCSVAction.setAllowCSVDownload(false));
    dispatch(myCSVAction.setIsFileDownload(false));
    dispatch(myCSVAction.setFileToExport("csv"));
  }, [CSV?.allowcsvDownload]);

  return (
    <Tooltip color="#605BFF" title={<h1 className="text-white"> Export CSV</h1>} >
      <Button
       onClick={() => handleButtonClick("csv")}
        icon={<LiaFileExportSolid size={24}/>}
        style={{width:"40px", height:"40px"}}
        loading={CSV?.isFetching}
        disabled={CSV?.isFetching}
        className="p-4"
      >
      </Button>
      <CSVLink
        data={csvData ?? []}
        headers={csvHeaders}
        ref={csvLinkRef}
        filename={`${currentDay}_${filename}`}
      />
    </Tooltip>
  );
};

export default CSVExportButton;
