import React, { useState } from "react";
import { Button, Drawer } from "antd";

interface drawerProp {
  components: any;
  openDrawer: boolean;
  closeDrower: any;
  title?: any;
  width?:any
}
const GeneralDrawer = ({
  components,
  openDrawer,
  closeDrower,
  title,
  width
}: drawerProp) => {
  return (
    <>
      <Drawer width={width} title={title} onClose={closeDrower} open={openDrawer}>
        {components}
      </Drawer>
    </>
  );
};

export default GeneralDrawer;
