import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "myZaka",
  initialState: {
    isFetching: false,
    isFetchingTransaction: false,
    all: { data: [] },
    customerTransaction: null,
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    providerBalance:null,
    listloans:null,
    CSVDownloadedData:null,
    loanId:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setCustomerTransaction(state, action) {
      state.customerTransaction = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFetchingTransaction(state, action) {
      state.isFetchingTransaction = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setProviderBalance(state, action) {
      state.providerBalance = action.payload;
    },
    setlistloans(state, action) {
      state.listloans = action.payload;
    },
    setCSVDownloadedData(state, action) {
      state.CSVDownloadedData = action.payload;
    },
    setLoanId(state, action) {
      state.loanId = action.payload;
    },
  },
});

export const myZakaActions = mySlice.actions;

export default mySlice.reducer;
