import { notification } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState, useRef, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import {
  Divider,
  Image,
  Form,
  Input,
  Space,
  SelectProps,
  Select,
  InputRef,
  Row,
  Col,
} from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";

import {
  createProductAction,
  getAllAttributesAction,
  updateProductsAction,
} from "../../store/product/actions";
import CloseIcon from "@mui/icons-material/Close";
import UploadComponent from "./Upload";

const { Option } = Select;

let indexBrand = 0;
let indexBrandType = 0;

const App: React.FC<{ dataToUpdate: any; action: String; onCancel?: any }> = ({
  dataToUpdate,
  action,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [pictures, setPictures] = useState([]);
  const [imageValue, setImageValue] = useState("");
  const [myImages, setMyImages] = useState<any[]>(
    dataToUpdate ? dataToUpdate?.images : []
  );
  const { product, auth} = useSelector((state: any) => state);
  const isUpdate = action === "update";
  const onFinish = async (values: any) => {
    const images = pictures.map((pic: any) => pic?.response?.data?.secure_url);
    if (!values.specs) {
      return notification.warning({
        message: "Please Add at least one Variant",
      });
    }

    if (true) {
      if (isUpdate) {
        auth?.token &&
          updateProductsAction(auth?.token, dataToUpdate._id, {
            brand: values.brand,
            model: values.model,
            type: values.type,
            specs: values.specs,
            images: [...images, ...myImages],
          })(dispatch);
      } else {
        auth?.token &&
          createProductAction(auth?.token, {
            brand: values.brand,
            model: values.model,
            type: values.type,
            specs: values.specs,
            images: [...images, ...myImages],
          })(dispatch);
      }
    }
    form.resetFields();
    onCancel();
  };

  const colorOptions: SelectProps["options"] = [];
  const [items, setItems] = useState([""]);
  const [itemsType, setItemsType] = useState([""]);
  const [name, setName] = useState("");
  const [nameType, setNameType] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [qtyUnitFilter, setQtyUnitFilter] = useState([]);
  const [classCodeFilter, setClassCodeFilter] = useState([]);
  const [taxFilter, setTaxFilter] = useState([]);
  const [pkgFilter, setPkgFilter] = useState([]);
  const [itemTypeFilter, setItemTypeFilter] = useState([]);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onNameChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameType(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addTypeItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItemsType([...itemsType, nameType || `New item ${indexBrandType++}`]);
    setNameType("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    auth?.token && getAllAttributesAction(auth?.token, "?")(dispatch);
    product?.attributes &&
      setItems(
        product.attributes.brands.map(function (ite: any) {
          return ite["value"];
        })
      );
    product?.attributes &&
      setItemsType(
        product.attributes.types.map(function (ite: any) {
          return ite["value"];
        })
      );
  }, [auth]);

  const onSearch = () => {
    setMyImages([...myImages, imageValue]);
    setImageValue("");
  };

  const HandleDelete = (im: string) => {
    console.log("Image: ", im);
    console.log("Images", myImages);
    setMyImages(myImages.filter((img: string) => img !== im));
  };

  // useEffect(() => {
  //   if (auth?.token) {
  //     getAllOptionsAction(auth?.token, "?")(dispatch);
  //   }
  // }, [auth?.token, dispatch]);

  type Option = {
    product?: {
      model?: string;
    };
    label?: string;
  };

  type SetFilteredOptions = React.Dispatch<
    React.SetStateAction<Option[] | any>
  >;

  const handleSearch = (
    value: string,
    data: any,
    filterFunction: (option: Option, value: string) => boolean,
    setFilteredOptions: SetFilteredOptions
  ) => {
    const filtered = data?.filter((option: any) =>
      filterFunction(option, value)
    );
    setFilteredOptions(filtered);
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={dataToUpdate}
      style={{ maxWidth: "100%" }}
      scrollToFirstError
      layout="vertical"
    >
      <div className="w-[20%] flex mx-[auto] my-2">
        <UploadComponent
          setPictures={setPictures}
          limit={1}
          isCard={"picture-card"}
        />
      </div>

      <div className="w-full pl-8">
        <Form.Item
          name="model"
          label="Product Model"
          rules={[
            {
              required: true,
              message: "Model is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="h-10" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Product Type"
          rules={[
            {
              required: true,
              message: "Please select type of the Product!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select/Add Type"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add Type"
                    ref={inputRef}
                    value={nameType}
                    onChange={onNameChangeType}
                  />
                  <Button variant="contained" onClick={addTypeItem}>
                    {" "}
                    <PlusOutlined />
                  </Button>
                </Space>
              </>
            )}
            options={itemsType.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="brand"
          label="Product Brand"
          rules={[
            {
              required: true,
              message: "Please select brand of the Product!",
            },
          ]}
        >
          <Select
            className="h-10"
            showSearch
            placeholder="Select/Add Brand"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add Brand"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                  />
                  <Button variant="contained" onClick={addItem}>
                    {" "}
                    <PlusOutlined />
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item) => ({ label: item, value: item }))}
          />
        </Form.Item>
        <Form.List name="specs">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Stack direction="column">
                  <div className="flex items-center space-x-4">
                    <Form.Item
                      {...restField}
                      name={[name, "label"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing labe of varient",
                        },
                      ]}
                    >
                      <Input placeholder="Label" style={{ width: "8rem" }} />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      className="mb-6"
                    />
                  </div>
                  <Form.Item
                    {...restField}
                    name={[name, "value"]}
                    rules={[{ required: true, message: "Missing Values" }]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "14rem" }}
                      placeholder="Values in form of Tags"
                      defaultValue={dataToUpdate?.specification}
                      options={colorOptions}
                    />
                  </Form.Item>
                </Stack>
              ))}
              <Form.Item>
                <Button
                  variant="contained"
                  onClick={() => add()}
                  startIcon={<PlusOutlined />}
                >
                  Add Variant
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div>
          <Form.Item label="Insert Picture Link">
            <Space.Compact style={{ width: "100%" }}>
              <Input
                value={imageValue}
                onChange={(e: any) => setImageValue(e.target.value)}
              />
              <Button
                onClick={() => {
                  onSearch();
                }}
              >
                Submit
              </Button>
            </Space.Compact>
          </Form.Item>
          <div className="flex flex-wrap gap-4">
            {myImages?.map((im: string) => (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid whiteSmoke",
                  borderRadius: "10px",
                  overflow: "hidden",
                  width: "100px",
                  height: "100px",
                  margin: "5px",
                }}
              >
                <button
                  type="button"
                  className="absolute top-0 right-0 z-10"
                  onClick={() => HandleDelete(im)}
                >
                  <CloseIcon />
                </button>
                <Image
                  src={im}
                  className="w-full h-full object-cover object-center"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
     
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="flex-start"
        justifyContent={"start"}
        sx={{ width: "100%", marginLeft: "30px" }}
      >
        <Stack sx={{ width: "75%" }}>
          <Form.Item>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ minWidth: "100%" }}
              loading={product?.isFetching}
            >
              {isUpdate ? "Update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Stack>
      </Stack>
    </Form>
  );
};
export default App;
