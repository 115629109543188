import { Carousel } from "antd";
import defaultPic from "../../../../assets/nophoto.png";
const AlertDeatails = (props: any) => {
  return (
    <>
      <div className="w-10/12 m-auto  bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
        {props?.data?.device?.at(0)?.product?.images?.length === 0 && (
          <img src={defaultPic} width={"100%"} alt="" />
        )}
        {props?.data?.device?.at(0)?.product?.images?.length > 0 && (
          <Carousel autoplay>
            {props?.data?.device?.at(0)?.product?.images?.map((im: any) => (
              <div className="block w-[59px] h-[87px]">
                <img src={im} alt="" className="w-full h-full object-cover" />
              </div>
            ))}
          </Carousel>
        )}
      </div>
      <div className="w-full mt-5">
        <h1 className="text-[#030229] font-medium text-xl capitalize">
          {props?.data?.product?.brand}
        </h1>
        <div className="flex gap-10">
          <p className="text-[#030229B2] text-sm py-1">Type:</p>
          <p className="text-[#030229B2] text-sm py-1">
            {props?.data?.device?.at(0)?.product?.type}
          </p>
        </div>
        <div className="flex gap-10">
          <p className="text-[#030229B2] text-sm py-1">Model:</p>
          <p className="text-[#030229B2] text-sm py-1">
            {props?.data?.device?.at(0)?.product?.model}
          </p>
        </div>
        <div className="flex gap-10">
          <p className="text-[#605BFF] text-sm py-1">Alert limit:</p>
          <p className="text-[#605BFF] text-sm py-1">
            {props?.data?.stockLimit}
          </p>
        </div>
        <div className="flex gap-10">
          <p className="text-[#030229B2] text-sm py-1">Channel:</p>
          <p className="text-[#030229B2] text-sm py-1">
            {props?.data?.device?.at(0)?.channel?.name}
          </p>
        </div>
      </div>
      <div className="mt-5">
        <h1 className="text-[#030229B2] text-sm py-1">Users To Be Notified </h1>
        <div className="flex gap-5 mt-2">
        {props?.data?.roleToNotify?.map((el: any) => {
          return (
            <>
              <div className="px-5 py-1 border w-fit border-[#605BFF] rounded-md mt-2 flex justify-center items-center">
                {el}
              </div>
            </>
          );
        })}
      </div>
      </div>
      <div className="mt-5">
        <h1 className="text-[#030229B2] text-sm py-1">Applied Shops </h1>
       {props?.data?.allShops? <div className="mt-3 text-lg"> All Shops</div>:<div className="flex gap-5 mt-2">
        {props?.data?.shops?.map((el: any) => {
          return (
            <>
              <div className="px-5 py-1 border w-fit border-[#605BFF] rounded-md mt-2 flex justify-center items-center">
                {el?.name}
              </div>
            </>
          );
        })}
      </div>}
      </div>
    </>
  );
};
export default AlertDeatails;
