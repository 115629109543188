import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import NosaleTable from "../../../../components/tables/NosaleTable";

const Nosale = (_props: any) => {
  return (
    <div className="text-black py-2 scrollbar-hide">

      <NosaleTable />
    </div>
  );
};

export default Nosale;
