import React, { useState } from "react";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "../../../components/cards/DetailsPage/DetailsTabs";
import { useNavigate } from "react-router-dom";

const DetailsPage = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className="text-black p-1">
      <DetailsHeaderActionBar
        goBack={goBack}
        pageName={props?.pageName}
        title={props?.title}
      />
      <div className="flex flex-row-reverse gap-2 pt-4">
        <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div>
    </div>
  );
};
export default DetailsPage;
