import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { Button } from "antd";
import AlertCard from "./AlertCard";
import AddStockAlertForm from "../../../../components/forms/AddStockAletForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryTrackctions } from "../../../../store/inventory/actions";
import LoadingCard from "../../../../components/cards/LoadingCard";
import GeneralDrawer from "../../../../components/Modals/GeneralDrawer";
import AlertDeatails from "./AlertDetails";

const InventoryAlert = () => {
  const { auth, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (auth?.token) {
      getAllInventoryTrackctions(auth?.token, `?`)(dispatch);
    }
  }, [auth, dispatch]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(false);

  const showDrawer = (data: any) => {
    setOpen(true);
    setData(data);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="mt-5 text-[#030229]">
        <DetailsHeaderActionBar pageName="Inventory" title="Alert" />
        <Button
          type="primary"
          onClick={showModal}
          className="float-right h-12 w-32"
        >
          Add device
        </Button>
        <Modal
          title={<h1 className="text-lg  text-[#030229]">Add Stock Alert</h1>}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          footer={null}
        >
          <AddStockAlertForm setIsModalOpen={setIsModalOpen} />
        </Modal>
        <div></div>
        <h1 className="pt-5">Added Devices</h1>
        {inventory?.isFetching ? (
          <div className="grid md:grid-cols-2 lg:md-grid-cols-3 xl:grid-cols-4 gap-4">
            {[1, 2, 3, 4, 5]?.map((_el: any) => {
              return (
                <>
                  <LoadingCard />
                </>
              );
            })}
          </div>
        ) : (
          <div className="grid md:grid-cols-2 lg:md-grid-cols-3 2xl:grid-cols-4 gap-4">
            {inventory?.allinventroyTrack?.data?.map((el: any) => {
              return (
                <>
                  <AlertCard data={el} onView={() => showDrawer(el)} onEdit={()=>showModal}/>
                </>
              );
            })}
          </div>
        )}
        <GeneralDrawer
          width={500}
          components={
            <div>
              <AlertDeatails data={data}/>
            </div>
          }
          openDrawer={open}
          closeDrower={onClose}
        />
      </div>
    </>
  );
};

export default InventoryAlert;
