import { myCSVAction } from ".";
import { getAllAccount } from "../account/services";
import { getCustomerInvoiceServices } from "../customer/services";
import { getAllServiceCustomer } from "../customer/services";
import { getPurchasedItemsService } from "../inventory/services";
import { myLayoutActions } from "../layout";
import {
  getAllApplicationService,
  getAllCustomerTransactionService,
  getlistloansService,
} from "../myZaka/services";
import { getAllNosaleService } from "../pos/services";
import { getAllService } from "../product/services";
import { getAllPurchaseOrdersService } from "../purchase/services";
import { getAllRepairsService } from "../repair/services";
import {
  getAllInventoryReportService,
  getAllSaveTheChildrenService,
  getAllTransferReportService,
  getTransactionByCustomerService,
} from "../report/services";
import { getAllServiceUser } from "../setting/services";
import { getAllSubdeliverynoteItem } from "../subscription/deliverynote/services";
import { getStockStats } from "../wareHouse/services";
import { getFinanceTransactionService, getPriceListService } from "./services";

export const getFinanceTransaction_CSVAction = (
  token: any,
  query?: any,
  fileType?: any
) => {
  return async (dispatch: any) => {
    try {
      if (fileType === "csv") {
        dispatch(myCSVAction.setIsFetching(true));
      } else {
        dispatch(myCSVAction.setIsFileFetching(true));
      }
      const res = await getFinanceTransactionService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        if (fileType === "csv") {
          dispatch(myCSVAction.setAllowCSVDownload(true));
        } else {
          dispatch(myCSVAction.setIsFileDownload(true));
        }
        dispatch(myCSVAction.setIsFetching(false));
        dispatch(myCSVAction.setIsFileFetching(false));
        dispatch(myLayoutActions.setSelectedShop(""));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
        dispatch(myLayoutActions.setSelectedShop(""));
        dispatch(myCSVAction.setIsFetching(false));
        dispatch(myCSVAction.setIsFileFetching(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
      dispatch(myCSVAction.setIsFileFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
      dispatch(myCSVAction.setIsFileFetching(false));
    }
  };
};

export const getAllNoSales_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllNosaleService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllProducts_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getStock_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getStockStats(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllPO_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllPurchaseOrdersService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllPurchased_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getPurchasedItemsService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllStockReport_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllInventoryReportService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getAllReportTransactions_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getCustomerInvoiceServices(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getAllTransferReport_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllTransferReportService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllSaveTheChildrenReport_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllSaveTheChildrenService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllPriceList_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getPriceListService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getSoldIMEIs_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllSubdeliverynoteItem(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllB2CAccount_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllAccount(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getAllUsers_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllServiceUser(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const customerDetails_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllServiceCustomer(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const applicantRequest_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllApplicationService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const repairReport_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllRepairsService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getTransactionByCustomer_CSVAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllCustomerTransactionService(token, query);
      console.log("lalal", res);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getTransactionGroupedByCustomer_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getTransactionByCustomerService(token, query);
      console.log("lalal", res);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

export const getlistloans_csvAction = (token: any, query?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getlistloansService(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
