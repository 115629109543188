import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import { authActions } from "../../store/auth";
import SideNav from "./SideNav";
import { myLayoutActions } from "../../store/layout";
import { useMediaQuery } from "@mui/material";
import SettingSideBar from "../../pages/dashboard/setting/SettingSideBar";
import { handleHoverSettingSidebar } from "../../store/layout/actions";

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { auth, layout } = useSelector((state: any) => state);
  const isSetting = location.pathname === "/setting";
  const isVoaults = location.pathname === "/vaults";
  const isSettingInPath = location.pathname.includes("/setting");

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    // dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);
  const elementRef = useRef<HTMLDivElement>(null);

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  const handleSideNav = () => {
    dispatch(myLayoutActions.setIsSideNavOpen(!layout.isSideNavOpen));
  };
  const smallScreen = useMediaQuery("(max-width:767px)");
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const isHovering = useRef(false);

  const handleMouseEnter = () => {
    isHovering.current = true;
    hoverTimeout.current = setTimeout(() => {
      if (isHovering.current) {
        handleHoverSettingSidebar(true)(dispatch);
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    isHovering.current = false;
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    handleHoverSettingSidebar(false)(dispatch);
  };

  return (
    <div className="landScreenBack">
      <div
        className={` gap-8  w-full h-screen overflow-y-auto ${!props?.dashback && "landScreen"
          } `}
      >
        <SideNav
          toggleSideNav={handleSideNav}
          selected={props?.selectedNav || 0}
          isOpen={layout.isSideNavOpen}
        />
        <div className="flex min-h-screen">
          {!props?.dashback && <div className="xw-fit"></div>}
          <Header
            nav={props?.nav}
            title={props?.title || " Samphone"}
            handleFullScreenClick={handleFullScreenClick}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
          <div
            className={
              `${isVoaults ? "" : "pb-4"}  h-[calc(100%-64px)] w-full ${smallScreen
                ? "px-4 pt-[3rem]"
                : `${isVoaults ? "" : "pr-10"} pt-[3rem] pbs-4  ${layout.isSideNavOpen
                  ? `${isSetting || isVoaults
                    ? "pl-[210px]"
                    : "pl-[230px]"
                  } `
                  : `${isSetting || isVoaults
                    ? "pl-[5rem]"
                    : "pl-[6rem]"
                  } `
                }`
              }`
            }
          >
            {props.children}
          </div>
        </div>
        {isSettingInPath &&
          auth.user?.role === "admin" &&
          auth.user.tag !== "mascom" && (
            <div
              // onMouseEnter={() => handleHoverSettingSidebar(true)(dispatch)}
              // onMouseLeave={() => handleHoverSettingSidebar(false)(dispatch)}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`${layout.isSideNavOpen ? "ml-52" : "ml-20"
                } bg-white  ${layout?.isSettingSidebarHovered
                  ? "2xl:w-[334px]"
                  : "w-[90px] 2xl:w-[80px] pt-8"
                }  fixed top-[57px] rounded-sm shadow-[0_35px_60px_-15px_rgba(3, 2, 41, 0.07)] h-screen overflow-auto pb-20 xl:pb-0 text-black transition-all ease-in-out duration-700`}
            >
              <SettingSideBar />
            </div>
          )}
      </div>
    </div>
  );
};

export default Layout;
