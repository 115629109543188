import React from "react";
import defaultPic from "../../../../assets/nophoto.png";
import { Carousel, Switch } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { GrView } from "react-icons/gr";
const AlertCard = (props: any) => {
  const items: MenuProps["items"] = [
    {
      type: "divider",
    },
    {
      label: (
        <div className="flex gap-3" onClick={props?.onView}><GrView   color="#2943D6" className="mt-1.5"/><p className="text-[#2943D6]">view</p></div>
      ),
      key: "2",
    },
  ];
  return (
    <div className="shadow-md border-l-[3px] py-3 px-5 w-[400pxs] bg-white cursor-pointer border-[#2943D6] rounded-md mt-5">
      <Dropdown menu={{ items }} trigger={["click"]} className="float-right">
        <BiDotsVerticalRounded color="black" />
      </Dropdown>
      <div className="flex gap-5">
        <div className="w-[98px] h-[110px] bg-[#F4F6FA] rounded-[10px] p-3 border-[.1px] border-[#D9DBE9] border-opacity-20">
          {props?.data?.device?.at(0)?.product?.images?.length === 0 && (
            <img src={defaultPic} width={"100%"} alt="" />
          )}
          {props?.data?.device?.at(0)?.product?.images?.length > 0 && (
            <Carousel autoplay>
              {props?.data?.device?.at(0)?.product?.images?.map((im: any) => (
                <div className="block w-[59px] h-[87px]">
                  <img src={im} alt="" className="w-full h-full object-cover" />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="w-full">
          <h1 className="text-[#030229] font-medium text-xl capitalize">
            {props?.data?.product?.brand}
          </h1>
          <div className="flex gap-2">
            <p className="text-[#030229B2] text-sm py-1">Type:</p>
            <p className="text-[#030229B2] text-sm py-1">
              {props?.data?.device?.at(0)?.product?.type}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="text-[#030229B2] text-sm py-1">Model:</p>
            <p className="text-[#030229B2] text-sm py-1">
              {props?.data?.device?.at(0)?.product?.model}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="text-[#605BFF] text-sm py-1">Alert limit:</p>
            <p className="text-[#605BFF] text-sm py-1">
              {props?.data?.stockLimit}
            </p>
          </div>
          <div className="flex gap-2">
            <p className="text-[#030229B2] text-sm py-1">Channel:</p>
            <p className="text-[#030229B2] text-sm py-1">
              {props?.data?.device?.at(0)?.channel?.name}
            </p>
          </div>
          <div className="flex justify-between w-full mt-2">
            <p className="text-[#030229B2] text-sm py-1">
              Applied to all Shops:
            </p>
            <Switch className="mt-1" disabled checked={props?.data?.allShops} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AlertCard;
