export const StockRequestHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specificatios", key: "specification" },
  { label: "Quantity Requested", key: "qtyReq" },
  { label: "Quantity Approved", key: "qtyAppreved" },
];

export const PurchaseOrdersHeaders = [
  { label: "Requisition Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Model", key: "items" },
  { label: "Quantity", key: "quantity" },
  { label: "Cost/Unit (BWP)", key: "cost" },
  { label: "Total Cost (BWP)", key: "totalCost" },
  { label: "Delivery Date", key: "deliveryDate" },
];

export const ShopDetailsHeaders = [
  { label: "Shop", key: "shop" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const TransactionsHeaders = [
  { label: "Date", key: "paidOn" },
  // { label: "Paid On", key: "date_time" },
  { label: "Shop", key: "shop_name" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "barCode" },
  { label: "IMEI2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "New SIM Card", key: "newSimCard" },
  { label: "Device Retail Prices (BWP)", key: "amount" },
  { label: "Discount", key: "discount" },
  { label: "Purchase Cost (BWP)", key: "puchaseCost" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Method Of Payment", key: "methodOfPayment" },
  { label: "Momo Transaction Id", key: "momoTransactionId" },
  { label: "Macye Macye Payment Type", key: "macyemacyeType" },
  { label: "Documents", key: "documents" },
  { label: "RRA ReceiptCode", key: "rraReceiptCode" },
  { label: "Status", key: "status" },
];

export const CustomerTransactionReportHeaders = [
  { label: "Date", key: "date" },
  { label: "Customer's Full Name", key: "customerName" },
  { label: "Customer Phone No", key: "customerTel" },
  { label: "Monthly Payment", key: "mPayment" },
  { label: "Total Amount Paid", key: "mPaid" },
  { label: "Amount Remain", key: "mPaidremain" },
  { label: "Paid At", key: "paidAt" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Status", key: "status" },
];
export const CustomerTransactionReportSumaryHeaders = [
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "phone" },
  { label: "Total Amount to be Paid", key: "amountToBePaid" },
  { label: "Total Amount Paid", key: "amountPaid" },
  { label: "Amount Remain", key: "AmountRemain" },
  { label: "Ending Date", key: "endingDate" },
];
export const TransactionReportHeaders = [
  { label: "Invoice", key: "invoice" },
  { label: "Installment Amount", key: "installmentAmount" },
  { label: "Amount Paid", key: "amountPaid" },
  { label: "Starting Date", key: "startingDate" },
  { label: "Due Date", key: "dueDate" },
  { label: "Account Id", key: "accountId" },
  { label: "Account Status", key: "AccountStatus" },
  { label: "Paid On", key: "paidOn" },
  { label: "Status", key: "status" },
];

export const SalesReportHeader = [
  { label: "Date", key: "paidOn" },
  { label: "Shop", key: "shop" },
  { label: "Region", key: "region" },
  { label: "Agent", key: "agent" },
  { label: "Customer's Full Name", key: "customer" },
  { label: "Customer Phone No", key: "customerPhone" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "IMEI 1", key: "barCode" },
  { label: "IMEI 2", key: "imei2" },
  { label: "Data Activated", key: "dataActivated" },
  { label: "Device Retail Prices (BWP)", key: "retailPrice" },
  { label: "Total Amount Paid", key: "totalAmount" },
  { label: "Transaction ID", key: "transactionId" },
  { label: "Method of Payment", key: "methodOfPayment" },
  { label: "Status", key: "status" },
];

export const WarehouseHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "In Shop", key: "inShop" },
  { label: "In Warehouse", key: "inWarehouse" },
  { label: "Sold", key: "sold" },
];

export const ProductDetailsHeaders = [
  { label: "Product", key: "product" },
  { label: "Shop", key: "shop" },
  { label: "Stock", key: "stock" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];

export const AllShopsDataHeaders = [
  { label: "Name", key: "name" },
  { label: "type", key: "type" },
  { label: "Location", key: "address" },
  { label: "Region", key: "region" },
];

export const NosalesHeaders = [
  { label: "Date", key: "date" },
  { label: "Shop", key: "shop" },
  { label: "Agent", key: "agent" },
  { label: "Customer Names", key: "customer" },
  { label: "MSISDN", key: "customerPhone" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Quantities", key: "quantity" },
  { label: "Reason", key: "reason" },
];

export const ProductsHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
];

export const ProductInfoStockHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Product", key: "product" },
  { label: "SerialNumber", key: "serialNumber" },
  { label: "Specification", key: "specification" },
  { label: "Station", key: "station" },
  { label: "Status", key: "status" },
];

export const ProductInfoPricesHeaders = [
  { label: "Channel", key: "channel" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Prices (BWP)", key: "prices" },
  { label: "Extended Warranty", key: "exWarranty" },
];

export const ProductInfoSuppliersHeaders = [
  { label: "Model", key: "model" },
  { label: "Supplier Names", key: "supplierName" },
  { label: "TIN", key: "tin" },
  { label: "Telephone", key: "telephone" },
  { label: "Email", key: "email" },
  { label: "Type", key: "type" },
  { label: "Address", key: "location" },
];

export const WarehouseInHeaders = [
  { label: "Date", key: "date" },
  { label: "Model", key: "model" },
  { label: "Quantity", key: "quantity" },
  { label: "Supplier", key: "supplier" },
  { label: "Supplier TIN", key: "supplierTIN" },
  { label: "Supplier Phone", key: "supplierPhone" },
  { label: "Supplier Email", key: "supplierEmail" },
  { label: "Shipping Type", key: "shippingTypeName" },
  { label: "Delivere Name", key: "delivererName" },
  { label: "Delivere Phone", key: "delivererPhone" },
];

export const PriceListHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Capacity", key: "storage" },
  { label: "Memory", key: "memory" },
  { label: "Price", key: "amount" },
];

export const PurchasedHeaders = [
  { label: "Date", key: "date" },
  { label: "Supplier", key: "supplier" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "Quantity Received", key: "quantity" },
  { label: "Unit Price", key: "amount" },
  { label: "Total", key: "total" },
];

export const StockHeaders = [
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "Status", key: "status" },
];

export const ShopReportHeader = [
  { label: "Date", key: "date" },
  { label: "Shop", key: "shop" },
  { label: "Region", key: "region" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "Opening Stock", key: "openingStock" },
  { label: "Closing Stock", key: "closingStock" },
];

export const InventoryTransferReportHeader = [
  { label: "Date", key: "date" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "ROM", key: "capacity" },
  { label: "RAM", key: "memory" },
  { label: "IMEI", key: "imei" },
  { label: "From Shop", key: "fromShop" },
  { label: "To Shop", key: "toShop" },
  { label: "Transfered by (Names)", key: "transferedBy" },
];

export const SoldDeviceHeaders = [
  { label: "Date", key: "date" },
  { label: "Customer's names", key: "customer" },
  { label: "Customer's telephone", key: "custTel" },
  { label: "Type", key: "type" },
  { label: "Brand", key: "brand" },
  { label: "Model", key: "model" },
  { label: "IMEI 1", key: "imei1" },
  { label: "IMEI 1", key: "imei2" },
  { label: "Storage(Capacity)", key: "storage" },
  { label: "Memory(RAM)", key: "memory" },
];

export const QuickbookHeader = [
  { label: "InvoiceNo", key: "invoiceNo" },
  { label: "Customer", key: "customer" },
  { label: "InvoiceDate", key: "invoiceDate" },
  { label: "DueDate", key: "dueDate" },
  { label: "Terms", key: "terms" },
  { label: "Location", key: "location" },
  { label: "Memo", key: "memo" },
  { label: "Item(Product/Service)", key: "item" },
  { label: "ItemDescription", key: "memo" },
  { label: "ItemQuantity", key: "itemQuantity" },
  { label: "ItemRate", key: "itemRate" },
  { label: "ItemAmount", key: "itemAmount" },
  { label: "ItemTaxCode", key: "itemTaxCode" },
  { label: "ItemTaxAmount", key: "itemTaxAmount" },
  { label: "Currency", key: "currency" },
  { label: "Service Date", key: "serviceDate" },
];

export const B2C_Account_Headers = [
  { label: "No", key: "no" },
  { label: "Date", key: "date" },
  { label: "Customer's Full Name", key: "name" },
  { label: "Customer Phone No", key: "tel" },
  { label: "Status", key: "status" },
];

export const AllUsersHeader = [
  { label: "Full Name", key: "userName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "telephone" },
  { label: "Role", key: "role" },
];


export const customerDetailsHeader = [
  { label: "Customer ID", key: "customerID" },
  { label: "Customer Type", key: "customerType" },
  { label: "First Name", key: "fname" },
  { label: "Last Name", key: "lname" },
  { label: "MSISDN", key: "customerPhone" },
  { label: "Gender", key: "gender" },
  { label: "Date of birth", key: "dob" },
  { label: "National ID", key: "nationalID" },
  { label: "Nationality", key: "nationality" },
  { label: "City", key: "city" },
  { label: "Address", key: "address" },
  { label: "Terms & Conditions", key: "tnc" },
  { label: "Verified", key: "varified" },
  { label: "status", key: "status" },
  { label: "Comment", key: "comment" },
];
export const applicantRequestHeader = [
  { label: "Date", key: "date" },
  { label: "Shop", key: "shop" },
  { label: "Customer ID", key: "customerId" },
  { label: "First Name", key: "fname" },
  { label: "Last Name", key: "lname" },
  { label: "MSISDN", key: "msisdn" },
  { label: "City", key: "city" },
  { label: "Address", key: "currentResidence" },
  { label: "Terms & Conditions", key: "tnc" },
  { label: "Loan Application ID", key: "loanAppID" },
  { label: "Upfront", key: "upfront" },
  { label: "Loan Tenure", key: "planPeriod" },
  { label: "Business Product Type", key: "businessProductType" },
  { label: "Picked up device", key: "pickedUpDevice" },
  { label: "Status", key: "status" },
];

export const customerInvoiceReportHeaders = [
  { label: "Invoice", key: "invoice" },
  { label: "Installment Amount", key: "installmentAmount" },
  { label: "Amount Paid", key: "amountPaid" },
  { label: "Starting Date", key: "startingDate" },
  { label: "Due Date", key: "dueDate" },
  { label: "Account ID", key: "accountId" },
  { label: "Paid On", key: "paidOn" },
  { label: "Status", key: "status" },
];

export const RepairDetailsHeader = [
  { label: "date", key: "date" },
  { label: "customer", key: "customer" },
  { label: "Model", key: "model" },
  { label: "serialNumber", key: "serialNumber" },
  { label: "shop", key: "shop" },
  { label: "repairedAt", key: "repairedAt" },
  { label: "status", key: "status" },
];

export const transactionsByCustomerHeaders = [
  { label: "Date", key: "date" },
  { label: "Transactions ID", key: "transaction" },
  { label: "EWP Transactions ID", key: "ewpTransaction" },
  { label: "MSISDN", key: "msisdn" },
  { label: "Amount (BWP)", key: "amount" },
  { label: "Type", key: "type" },
  { label: "Status", key: "status" },
];


export const myzakarecentransactionHeaders = [
  { label: "Loan Id", key: "loanid", width: 100 },
  { label: "Loan Transaction Id",  key: "loantransactionid", width: 200 },
  { label: "Transaction Created",  key: "transactioncreated", width: 200 },
  { label: "External Loan Id",  key: "externalloanid", width: 200 },
  { label: "Financial Transaction Id", key: "financialtransactionid", width: 200 },
  { label: "Loan Transaction Status",  key: "loantransactionstatus", width: 200 },
  { label: "Loan Transtatus Last Update",  key: "loantranstatuslastupdate", width: 200 },
  { label: "Loan Provider Identity",  key: "loanprovideridentity", width: 200 },
  { label: "Loan Provider Name",  key: "loanprovidername", width: 200 },
  { label: "Loan Provider Username",  key: "loanproviderusername", width: 200 },
  { label: "Consumer Identity",  key: "consumeridentity", width: 200 },
  { label: "Initiating User Identity",  key: "initiatinguseridentity", width: 200 },
  { label: "Amount", key: "amount", width: 100 },
  { label: "Financial Transaction Status",  key: "financialtransactionstatus", width: 200 },
  { label: "Financial Transtatus Last Update",  key: "financialtranstatuslastupdate", width: 200 },
  { label: "External Transaction Status", key: "externaltransactionstatus", width: 200 },
  { label: "External Transtatus Last Update",  key: "externaltranstatuslastupdate", width: 200 },
];