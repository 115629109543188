import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllApplicationService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/myZaka/application${query}`, token);
};

export const getAllCustomerTransactionService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/myZaka/transactions${query}`, token);
};



export const getProviderBalanceService = async (
  token: string
) => {
  return HttpRequest.post(`${SERVER_URL}/myZaka/getProviderBalance`,{}, token);
};

export const getlistloansService = async (
  token: string,query:any
) => {
  return HttpRequest.get(`${SERVER_URL}/myZaka/listloantransactions${query}`, token);
};


export const getloanapplicationService = async (
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/myZaka/application`, token);
};

