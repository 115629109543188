import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    isLoafding: false,
    isSideNavOpen: false,
    isVaultSideNavOpen: false,
    isNotAuthorised: false,
    isSettingSidebarHovered: false,
    viewUser: false,
    viewChannel: false,
    channelInfo: null,
    viewProductHistory: false,
    productHistoryInfo: null,
    curretRoute: null,
    prevRoute: null,
    selectedMenu: null,
    redirectPath: null,
    userInfo: null,
    selectedChannel: null,
    selectedShop: "",
    selectedTheme: "light",
    openCSVButton: false,
    dashboardPreview: "",
    isFranchiseeDash: false,
    isCustomertableview: "grid view",
    activeTab: 0,
    selectedAccountType: 0,
    isUpdateAccount: false,
    searchFrom: null,
    searchResult: "",
    subscriptionType: "subscription",
    subscriptionDealSteps: 0,
    foundCustomerByPhone: null,
    isNewCustomer: false,
    priceListTab: "subscription",
    settingActiveSidebarTab: "profile",
    dataToExport: null,
  },
  reducers: {
    setIsSideNavOpen(state, action) {
      state.isSideNavOpen = action.payload;
    },
    setIsVaultSideNavOpen(state, action) {
      state.isVaultSideNavOpen = action.payload;
    },
    setViewUser(state, action) {
      state.viewUser = action.payload;
    },
    setViewChannel(state, action) {
      state.viewChannel = action.payload;
    },
    setSelectedTheme(state, action) {
      state.selectedTheme = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoafding = action.payload;
    },
    setCurretRoute(state, action) {
      state.curretRoute = action.payload;
    },
    setPrevRoute(state, action) {
      state.prevRoute = action.payload;
    },
    setSelectedMenu(state, action) {
      state.selectedMenu = action.payload;
    },
    setRedirectPath(state, action) {
      state.redirectPath = action.payload;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    setChannelInfo(state, action) {
      state.channelInfo = action.payload;
    },
    setViewProductHistory(state, action) {
      state.viewProductHistory = action.payload;
    },
    setProductHistoryInfo(state, action) {
      state.productHistoryInfo = action.payload;
    },
    setSelectedChannel(state, action) {
      state.selectedChannel = action.payload;
    },
    setSelectedShop(state, action) {
      state.selectedShop = action.payload;
    },
    setOpenCSVButton(state, action) {
      state.openCSVButton = action.payload;
    },
    setDashboardPreview(state, action) {
      state.dashboardPreview = action.payload;
    },

    setIsFranchiseeDash(state, action) {
      state.isFranchiseeDash = action.payload;
    },

    setIsCustomertableview(state, action) {
      state.isCustomertableview = action.payload;
    },

    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },

    setSelectedAccountType(state, action) {
      state.selectedAccountType = action.payload;
    },
    setIsUpdateAccount(state, action) {
      state.isUpdateAccount = action.payload;
    },
    setSearchFrom(state, action) {
      state.searchFrom = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setSubscriptionType(state, action) {
      state.subscriptionType = action.payload;
    },
    setSubscriptionDealSteps(state, action) {
      state.subscriptionDealSteps = action.payload;
    },
    setFoundCustomerByPhone(state, action) {
      state.foundCustomerByPhone = action.payload;
    },
    setIsNewCustomer(state, action) {
      state.isNewCustomer = action.payload;
    },
    setPriceListTab(state, action) {
      state.priceListTab = action.payload;
    },

    setIsNotAuthorised(state, action) {
      state.isNotAuthorised = action.payload;
    },
    setSettingActiveSidebarTab(state, action) {
      state.settingActiveSidebarTab = action.payload;
    },
    setIsSettingSidebarHovered(state, action) {
      state.isSettingSidebarHovered = action.payload;
    },
    setDataToExport(state, action) {
      state.dataToExport = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;
