import React, { useEffect } from "react";
import type { DatePickerProps } from "antd";
import { Space, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllRepairsAction } from "../../../store/repair/actions";
import { ReactComponent as Repairs } from "../../../assets/icons/repairs.svg";
import { Table } from "antd";
import DateRangeSelector from "../../../pages/dashboard/channels/dateRangePicke";
import type { TableColumnsType } from "antd";
import { searchValue } from "../../../utils/setColor";
import defaultPic from "../../../assets/nophoto.png";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

interface DataType {
  key: React.Key;
  device: string;
  foto: string;
  imei: any;
  shop: string;
  agent: string;
  status: any;
}

const RepairOverview = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const repair = [
    {
      label: "All  Devices",
      value: repairs?.all?.total || 0,
    },
    {
      label: "Completed",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "completed")
          ?.length || 0,
    },
    {
      label: "In Transit",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "transit-out")
          ?.length || 0,
    },
    {
      label: "Pending",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "pending")
          ?.length || 0,
    },
    {
      label: "Cancelled",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "cancelled")
          ?.length || 0,
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (text, record) => {
        return (
          <Space>
            <img
              className="w-10 h-10 rounded-full"
              src={record?.foto ? record?.foto : defaultPic}
              alt="customer avatar"
            />
            <p className="capitalize"> {text?.toLowerCase()}</p>
          </Space>
        );
      },
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, _) => {
        return (
          <>
            <Tag
              style={{ width: "6rem ", textAlign: "center" }}
              color={searchValue(text)}
            >
              {text}
            </Tag>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(auth?.token, `?page=0&limit=500`)(dispatch);
  }, [auth?.token, dispatch]);

  const recentactivities = repairs?.all?.data?.slice(0, 5)?.map((el: any) => {
    return {
      key: el?._id,
      device: el?.cartItemId?.priceProduct?.product?.model?.toLowerCase(),
      imei: el?.cartItemId?.warehouseProduct?.serialNumber,
      shop: el?.cartItemId?.shopStockItem?.shop?.name,
      agent: el?.createdBy?.names?.toLowerCase(),
      foto: el?.cartItemId?.priceProduct?.product?.images?.at(0),
      status: el?.status,
    };
  });

  return (
    <div className="text-[#030229] py-6 scrollbar-hide overflow-y-auto h-screen">
         <DetailsHeaderActionBar
        goBack={goBack}
        pageName={'Repair'}
        title={'Overview'}
      />
      <div className="flex gap-8 flex-wrap mt-5">
        {repair?.map((data: any) => (
          <div className="bg-white w-[18rem] p-6 flex gap-8 rounded-lg">
            <Repairs />
            <span>
              <h1 className="font-semibold text-xl opacity-80 leading-loose">
                {data?.value}
              </h1>
              <p className="opacity-70">{data?.label}</p>
            </span>
          </div>
        ))}
      </div>
      <div className="flex flex-col-reverse md:flex-row mt-6 px-4 gap-3">
        <div className="w-[100%] md:w-[65%] bg-white rounded-lg">
          <h1 className="p-2 font-medium py-4">Recent Activities</h1>
          <Table
            columns={columns}
            dataSource={recentactivities}
            pagination={false}
            scroll={{ x: true }}
          />
        </div>
          <DateRangeSelector />
   
      </div>
    </div>
  );
};

export default RepairOverview;
