import React from "react";
import { Card, Typography, Row, Col, Button, Tag, Table } from "antd";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneDeliveryNoteAction } from "../../../../store/wareHouse/actions";
import { searchValue } from "../../../../utils/setColor";
import { ColumnsType } from "antd/es/table";
import { getDefaultCurrencyCode } from "../../../../utils/helper";

const { Title, Text } = Typography;

const DeliveryDetails = () => {
  const { wareHouse, auth, company } = useSelector((state: any) => state);
  const { deliveryNoteId } = useParams();
  const dispatch = useDispatch();
  const deliveryNote = wareHouse?.selectedDeliveryNote?.data;

  React.useEffect(() => {
    auth?.token &&
      getOneDeliveryNoteAction(auth?.token, deliveryNoteId as string)(dispatch);
  }, [auth?.token, deliveryNoteId, dispatch]);

  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price/Unit (BWP)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  const data: {
    _id: any;
    type: any;
    brand: any;
    model: any;
    description: any;
    quantity: any;
    price: any;
    total: any;
  }[] = [];

  deliveryNote?.listOfItems?.forEach((item: any) => {
    data.push({
      _id: item?._id,
      type: item?.purchaseItem?.quotationItem?.product?.type,
      brand: item?.purchaseItem?.quotationItem?.product?.brand,
      model: item?.purchaseItem?.quotationItem?.product?.model,
      description: item?.purchaseItem?.quotationItem?.specification,
      quantity: item?.qtySent,
      price: item?.purchaseItem?.priceUnit,
      total: item?.qtySent + item?.purchaseItem?.priceUnit,
    });
  });

  return (
    <div className="p-4">
      <div className="text-[#0F0F47] my-4">
        <DetailsHeaderActionBar pageName="Delivery" title="Purchase Order" />
      </div>
      <Card>
        <Row gutter={16} className="pb-4">
          <Col span={12}>
            <Title level={4}>{deliveryNote?.pOrderID?.supplier?.name}</Title>
            <Text>{deliveryNote?.pOrderID?.supplier?.address}</Text>
            <br />
            <Text>{deliveryNote?.pOrderID?.supplier?.phone}</Text>
            <br />
            <Text>{deliveryNote?.pOrderID?.supplier?.email}</Text>
            <br />
            <Text>{deliveryNote?.pOrderID?.supplier?.tin}</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Tag color={searchValue(deliveryNote?.status)}>
              {deliveryNote?.status}
            </Tag>
            <br />
            <Text>Created On: {deliveryNote?.createdAt.slice(0, 10)}</Text>
            <br />
          </Col>
        </Row>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowKey="_id"
          summary={(pageData) => {
            let totalAmount = 0;
            pageData.forEach(({ total }) => {
              totalAmount += total;
            });

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={6}>
                  Total Amount
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Typography.Text>
                    {getDefaultCurrencyCode(company)}{" "}
                    {totalAmount.toLocaleString()}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Card>
      <div className="flex flex-row justify-end items-center w-[90%]">
        <Button className="bg-[#605BFF] text-white">Next</Button>
      </div>
    </div>
  );
};

export default DeliveryDetails;
