import { myZakaActions } from ".";
import {
  getAllApplicationService,
  getAllCustomerTransactionService,
  getProviderBalanceService,
  getlistloansService,
  getloanapplicationService,
} from "./services";

export const getAllApplicationAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myZakaActions.setIsFetching(true));
      const res = await getAllApplicationService(token, query);
      if (res?.status === 200) {
        dispatch(myZakaActions.setAll(res));
        dispatch(myZakaActions.setIsFetching(false));
      }
      dispatch(myZakaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllTransactionByCustomerAction = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myZakaActions.setIsFetchingTransaction(true));
      const res = await getAllCustomerTransactionService(token, query);
      if (res?.status === 200) {
        dispatch(myZakaActions.setCustomerTransaction(res));
        dispatch(myZakaActions.setIsFetchingTransaction(false));
      }
      dispatch(myZakaActions.setIsFetchingTransaction(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProviderBalanceAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myZakaActions.setIsFetching(true));
      const res = await getProviderBalanceService(token);
      if (res?.status === 200) {
        dispatch(myZakaActions.setProviderBalance(res));
        dispatch(myZakaActions.setIsFetching(false));
      }
      dispatch(myZakaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getlistloansAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myZakaActions.setIsFetching(true));
      const res = await getlistloansService(token, query);
      if (res?.status === 200) {
        dispatch(myZakaActions.setlistloans(res));
        dispatch(myZakaActions.setIsFetching(false));
      }
      dispatch(myZakaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getloanapplicationAction = (token: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myZakaActions.setIsFetching(true));
      const res = await getloanapplicationService(token);

      if (res?.status === 200) {
        dispatch(myZakaActions.setLoanId(res));
        dispatch(myZakaActions.setIsFetching(false));
      }
      dispatch(myZakaActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
