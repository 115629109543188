export function isNumber(n: any) {
  console.log("NUMBER", n);
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

const storeSelectedCaption = (selectedCaption: any) => {
  localStorage.setItem("selectedCaption", selectedCaption);
};
const storeSelectedSubVault = (selectedSubVault: any) => {
  localStorage.setItem("selectedSubVault", selectedSubVault);
};

const storeActiveMenu = (activeMenu: any) => {
  localStorage.setItem("activeCollapse", activeMenu);
};
const storeActiveVault = (activeVault: any) => {
  localStorage.setItem("activeCollapseVault", activeVault);
};

const getActiveMenu = () => {
  const activeOne = localStorage.getItem("activeCollapse");
  if (activeOne !== null) {
    return parseInt(activeOne);
  } else {
    return 0; // or any default value you prefer
  }
};
const getActiveVault = () => {
  const activeVaultOne = localStorage.getItem("activeCollapseVault");
  if (activeVaultOne !== null) {
    return parseInt(activeVaultOne);
  } else {
    return 0; // or any default value you prefer
  }
};

export const getDefaultCurrencyCode = (companyInfos: any) => {
  return `${
    companyInfos?.selected?.currency?.filter((d: any) => d?.isDefault)[0]
      ?.currencyCode ?? "BWP"
  } `;
};
export {
  storeActiveMenu,
  getActiveMenu,
  storeSelectedCaption,
  storeActiveVault,
  storeSelectedSubVault,
  getActiveVault,
};

export const getCountsByStatus = (data: any, status: string) => {
  if (!Array.isArray(data)) {
    return { totalCountDay1: 0, totalCountDay2: 0 };
  }

  if (typeof status !== "string" || status.trim() === "") {
    return { totalCountDay1: 0, totalCountDay2: 0 };
  }

  const result = data.find((item) => item.status === status);

  return result
    ? {
        totalCountDay1: result.totalCountDay1,
        totalCountDay2: result.totalCountDay2,
      }
    : { totalCountDay1: 0, totalCountDay2: 0 };
};

export const getNewestDateByKey = <T extends Record<string, string>>(
  payments: T[],
  dateKey: keyof T
): string | null => {
  if (!payments.length) return null;

  const newestPayment = payments.reduce((latest, current) =>
    new Date(latest[dateKey]) > new Date(current[dateKey]) ? latest : current
  );

  return newestPayment[dateKey];
};

export const ensureArrayResponse = (data: any) => {
  if (Array.isArray(data)) {
    return data;
  } else if (data !== null && typeof data === "object") {
    return [data];
  }
  return [];
};
