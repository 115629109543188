import react, { useEffect, useState } from "react";
import * as aiIcon from "react-icons/ai";
import { Modal } from "antd";
import "../customSwuitch.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction, getOneChanneltAction } from "../../../../store/channel/actions";
import HeaderComponent from "../../../../components/HeaderChannel";
import AddNewChannel from "../../../../components/forms/newChannelForm";
import { Avatar, List, Skeleton } from "antd";
import UpdateChannelForm from "./updateChannelForm";
import { viewChannelDetails } from "../../../../store/layout/actions";
import ChannelDetailsPage from "./channelDetailsPage";

const Channels = () => {
  const { auth, layout, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [userChannel, setUserChannel] = useState<any>("");

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  const styles = {
    custom: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleViewChannel = (value: any) => {
    viewChannelDetails(true, value)(dispatch);
    setUserChannel(value);
    console.log(value,"values")
    getOneChanneltAction(value?._id, auth?.token)(dispatch)
  };

  const [isUpdateModalOpen, setIsUpdateModelOpen] = useState(false);

  const updateModelOpen = (value: any) => {
    setUpdateData(value);
    setIsUpdateModelOpen(true);
  };
  const updateCancel = () => {
    setUpdateData(" ");
    setIsUpdateModelOpen(false);
  };
  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };
  const allChannels = channel?.all?.data;
  return (
    <>
      {layout?.viewChannel ? (
        <ChannelDetailsPage />
      ) : (
        <div>
          <header
            className={`py-2 p-2 bg-[#F4F6FA] ${layout.isSideNavOpen ? "w-[68%]" : "w-[75%]"
              }  fixed top-[60px] z-10`}
          >
            <div
              className={`flex gap-20 justify-between pr-20  mt-3 bg-[#F4F6FA] h-[100px] ${layout.isSideNavOpen ? "w-[90%]" : "w-[100%] "
                }`}
            >
              <div>
                <h1 className="text-[20px] text-[#0F0F47] font-[600]">
                  Channel List
                </h1>
                <p className="text-[#0F0F47B2] pt-2 font-normal text-sm">
                  You have {channel?.all?.total} specific channels.
                </p>
              </div>
              <Modal
                title={"Update Channel"}
                open={isUpdateModalOpen}
                onCancel={updateCancel}
                footer={null}
                className="min-w-min"
              >
                <div style={style}>
                  <UpdateChannelForm
                    data={updateData}
                    //  channelId={channelId}
                    onCancel={updateCancel}
                  />
                </div>
              </Modal>
              <HeaderComponent
                item="Channel"
                modelTitle="Add New Channel"
                // filterCard={<ChannelFilter />}
                isNotCollapse={true}
                ModelComponent={<AddNewChannel onCancel={handleCancel} />}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleCancel={handleCancel}
              />
            </div>
          </header>
          <div className="h-[88vh] overflow-y-auto ">
            <div className="mt-32 mr-4">
              {channel.isFetching ? (
                <div>
                  {" "}
                  <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={allChannels}
                    renderItem={() => (
                      <List.Item>
                        <Skeleton loading={channel?.isFetching} active avatar>
                          <List.Item.Meta
                            avatar={<Avatar src="" />}
                            // title={<a href={item.href}>{item.title}</a>}
                            description=""
                          />
                          "content"
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                allChannels?.map((el: any, index: any) => (
                  <div
                    className="bg-white rounded-md mt-3 p-2 h-32"
                    style={styles.custom}
                  >
                    <div className="relative px-6 py-6">
                      <div className="flex gap-4">
                        <img
                          src={el?.logo}
                          alt="Logo"
                          width={42}
                          className="rounded-full h-14 w-14"
                        />
                        <h1 className="text-[#0F0F47] font-medium text-lg mt-[auto] mb-[auto]">
                          {el.name}
                        </h1>
                      </div>
                      <div>
                        <button
                          className="bg-[#E5E5E5] h-7 rounded-[5px] px-2 flex gap-2 pt-1 absolute top-2 right-2"
                          onClick={() => updateModelOpen(el)}
                        >
                          <aiIcon.AiOutlineEdit color="#0F0F4780" />
                          <span className="text-[#0F0F4780] text-[12px] pt-1 font-normal">
                            Edit
                          </span>
                        </button>
                        <div
                          className=" text-[#605BFF] text-sm cursor-pointer absolute bottom-0 right-2"
                          onClick={() => handleViewChannel(el)}
                        >
                          View more
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Channels;
