import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Form,
  Col,
  notification,
  Input,
  DatePicker,
  DatePickerProps,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { updateRepairStatusAction } from "../../store/repair/actions";

const RepairDelivererOutForm = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { auth, repairs } = useSelector((state: any) => state);
  const [date, setDate] = useState("");

  const onFinish = async (values: any) => {
    await updateRepairStatusAction(auth?.token, props?.completedDataId?.key, {
      delivererOut: {
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        company: values?.company,
        date: date,
      },
      status: "transit-out",
      isNotify: true,
      notification: {
        action: "Repair Completed",
        role: ["admin", "sales-supervisor", "sales-agent", "technician"],
        message: `${props?.completedDataId?.model} Repaired Succesfully`,
        title: "Repair Completed",
      },
    })(dispatch);
    notification.success({ message: "Well Done :)" });
    form.resetFields();
    props?.onCancel();
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current < moment().startOf("day")) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="w-4/5">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{}}
          // style={{ minWidth: 900 }}
          scrollToFirstError
          layout="vertical"
        >
          <div className="">
            <h1 className="font-semibold text-xl text-gray-900 pb-4 flex items-center justify-center">
              {`Deliverer for ${props?.completedDataId?.model}`}
            </h1>

            <Form.Item
              name="name"
              label="Names"
              rules={[
                {
                  required: true,
                  message: "Name is required",
                  whitespace: true,
                },
              ]}
            >
              <Input className="h-10" />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Telephone"
              rules={[
                {
                  required: true,
                  message: "Telephone is required!",
                  whitespace: true,
                },
              ]}
            >
              <Input type="number" className="h-10" />
            </Form.Item>

            <Form.Item name="email" label="Email">
              <Input type="email" className="h-10" />
            </Form.Item>

            <Form.Item
              name="company"
              label="Company"
              rules={[
                {
                  required: true,
                  message: "Company is required!",
                  whitespace: true,
                },
              ]}
            >
              <Input className="h-10" />
            </Form.Item>

            <Form.Item name="date" label="Date">
              <DatePicker
                onChange={onChangeDate}
                disabledDate={disabledDate}
                className="w-full h-10"
              />
            </Form.Item>

            <Col
              span={24}
              className="w-2/5 flex items-center justify-between mx-20 mt-4"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ minWidth: 300 }}
                loading={repairs.isFetching}
              >
                Submit
              </LoadingButton>
            </Col>
          </div>
        </Form>
      </div>
    </>
  );
};

export default RepairDelivererOutForm;
