import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";

const AddressDetailsForm = (props: any) => {
  const [form] = Form.useForm();
  const [showRest, setShowRest] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedFirstLevel, setSelectedFirstLevel] = useState("");
  const [selectedSecondLevel, setSelectedSecondLevel] = useState("");
  const [selectedThirdLevel, setSelectedThirdLevel] = useState("");
  const [selectedFourthLevel, setSelectedFourthLevel] = useState("");
  const [selectedFifthLevel, setSelectedFifthLevel] = useState("");
  const [firstLevelAdminAreas, setFirstLevelAdminAreas] = useState<any[]>([]);
  const [secondLevelAdminAreas, setSecondLevelAdminAreas] = useState<any[]>([]);
  const [thirdLevelAdminAreas, setThirdLevelAdminAreas] = useState<any[]>([]);
  const [fourthLevelAdminAreas, setFourthLevelAdminAreas] = useState<any[]>([]);
  const [fifthLevelAdminAreas, setFifthLevelAdminAreas] = useState<any[]>([]);

  const isRwanda = selectedCountry === "RW";
  const isBurundi = selectedCountry === "BI";
  const isUSA = selectedCountry === "US";

  const countryOptions = Country.getAllCountries().map((country: any) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const getFirstLevelLabel = () => {
    if (isUSA) return "State";
    if (isRwanda || isBurundi) return "Province";
    return "Province/State";
  };

  const getSecondLevelLabel = () => {
    if (isUSA) return "County";
    if (isRwanda || isBurundi) return "District";
    return "District/County";
  };

  useEffect(() => {
    if (selectedCountry) {
      const states = State.getStatesOfCountry(selectedCountry);
      setFirstLevelAdminAreas(
        states.map((state: any) => ({
          value: state.isoCode,
          label: state.name,
        }))
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedFirstLevel) {
      if (isRwanda) {
        try {
          setSecondLevelAdminAreas(
            Districts(selectedFirstLevel).map((val: string) => ({
              label: val,
              value: val,
            }))
          );
        } catch (e: any) {
          setThirdLevelAdminAreas([]);
          setSelectedThirdLevel("");
        }
      } else {
        const cities = City.getCitiesOfState(
          selectedCountry,
          selectedFirstLevel
        );
        setSecondLevelAdminAreas(
          cities.map((city: any) => ({
            value: city.name,
            label: city.name,
          }))
        );
      }
    }
  }, [selectedCountry, selectedFirstLevel]);

  useEffect(() => {
    if (isRwanda && selectedFirstLevel && selectedSecondLevel) {
      try {
        setThirdLevelAdminAreas(
          Sectors(selectedFirstLevel, selectedSecondLevel).map(
            (val: string) => ({
              label: val,
              value: val,
            })
          )
        );
      } catch (e: any) {
        setSelectedFourthLevel("");
        setFourthLevelAdminAreas([]);
      }
    }
  }, [selectedSecondLevel, secondLevelAdminAreas, isRwanda]);

  useEffect(() => {
    if (
      isRwanda &&
      selectedFirstLevel &&
      selectedSecondLevel &&
      selectedThirdLevel
    ) {
      try {
        setFourthLevelAdminAreas(
          Cells(
            selectedFirstLevel,
            selectedSecondLevel,
            selectedThirdLevel
          ).map((val: string) => ({
            label: val,
            value: val,
          }))
        );
      } catch (e: any) {
        setSelectedFifthLevel("");
        setFifthLevelAdminAreas([]);
      }
    }
  }, [thirdLevelAdminAreas, selectedThirdLevel, isRwanda]);

  useEffect(() => {
    if (
      isRwanda &&
      selectedFirstLevel &&
      selectedSecondLevel &&
      selectedThirdLevel &&
      selectedFourthLevel
    ) {
      try {
        setFifthLevelAdminAreas(
          Villages(
            selectedFirstLevel,
            selectedSecondLevel,
            selectedThirdLevel,
            selectedFourthLevel
          ).map((val: string) => ({
            label: val,
            value: val,
          }))
        );
      } catch (e: any) {
        console.log(e);
      }
    }
  }, [fourthLevelAdminAreas, selectedFourthLevel, isRwanda]);

  useEffect(() => {
    if (props?.dataToUpdate) {
      form.setFieldsValue(props?.dataToUpdate?.shopLocation);
    } else {
      form.resetFields();
    }
  }, [props?.dataToUpdate]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => {
    return option?.label.toLowerCase().includes(input.toLowerCase()) || false;
  };

  return (
    <div className="mx-4 mt-4 lg:max-2xl:mt-2 lg:max-2xl:ml-0">
      <div className="grid grid-cols-3 space-x-2 gap-x-4">
        <Form.Item
          name="country"
          label={<span className="text-[#0F0F47] text-sm">Country</span>}
          rules={[
            {
              required: true,
              message: "Country is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            className="h-10"
            placeholder="Select Country"
            optionFilterProp="children"
            onChange={(value: string) => {
              setSelectedCountry(value);
              form.resetFields([
                "firstLevel",
                "secondLevel",
                "thirdLevel",
                "fourthLevel",
                "fifthLevel",
              ]);
            }}
            filterOption={filterOption}
            options={countryOptions}
            allowClear
          />
        </Form.Item>

        {selectedCountry && (
          <Form.Item
            name="firstLevel"
            label={
              <span className="text-[#0F0F47] text-sm">
                {getFirstLevelLabel()}
              </span>
            }
            rules={[
              {
                required: true,
                message: `${getFirstLevelLabel()} is required!`,
                whitespace: true,
              },
            ]}
          >
            <Select
              showSearch
              className="h-10"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={filterOption}
              onChange={(value: any) => {
                setSelectedFirstLevel(value);
                form.resetFields([
                  "secondLevel",
                  "thirdLevel",
                  "fourthLevel",
                  "fifthLevel",
                ]);
              }}
              options={
                isRwanda
                  ? Provinces().map((pro: string) => ({
                      label: pro,
                      value: pro,
                    }))
                  : firstLevelAdminAreas
              }
              allowClear
            />
          </Form.Item>
        )}

        {selectedCountry && selectedFirstLevel && (
          <Form.Item
            name="secondLevel"
            label={
              <span className="text-[#0F0F47] text-sm">
                {getSecondLevelLabel()}
              </span>
            }
            rules={[
              {
                required: false,
                message: `${getSecondLevelLabel()} is required!`,
                whitespace: true,
              },
            ]}
          >
            <Select
              showSearch
              className="h-10"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={filterOption}
              onChange={(value: any) => {
                setSelectedSecondLevel(value);
                if (isRwanda) {
                  form.resetFields(["thirdLevel", "fourthLevel", "fifthLevel"]);
                }
              }}
              options={secondLevelAdminAreas}
              allowClear
            />
          </Form.Item>
        )}

        {(isRwanda || isBurundi) && showRest && (
          <>
            <Form.Item
              name="thirdLevel"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Sector</span>}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Sector is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                className="h-10"
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value: any) => {
                  setSelectedThirdLevel(value);
                  form.resetFields(["fourthLevel", "fifthLevel"]);
                }}
                options={thirdLevelAdminAreas}
                value={selectedThirdLevel}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="fourthLevel"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Cell</span>}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Cell is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                className="h-10"
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value: any) => {
                  setSelectedFourthLevel(value);
                  form.resetFields(["fifthLevel"]);
                }}
                options={fourthLevelAdminAreas}
                value={selectedFourthLevel}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="fifthLevel"
              className="lg:max-2xl:-mt-5"
              label={<span className="text-[#0F0F47] text-sm">Village</span>}
              rules={[
                {
                  required: props?.dataToUpdate ? false : true,
                  message: "Village is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                showSearch
                className="h-10"
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={(value: any) => setSelectedFifthLevel(value)}
                options={fifthLevelAdminAreas}
                value={selectedFifthLevel}
                allowClear
              />
            </Form.Item>
          </>
        )}
      </div>
    </div>
  );
};

export default AddressDetailsForm;
